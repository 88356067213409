module.exports = {
  mainPage: "",
  player: "https://dev.automate.video",
  socketIo: "https://api2.devs.automate.video",
  appAddress: "https://dev-app.automate.video",
  oldPlayer: "https://dev-old.automate.video",
  mediacache: "https://dev-mediacache.automate.video",
  /*socketIo: "https://rechart-dev-api.azurewebsites.net", */
  playerkey: "SDFJIWR3sssdgwwerweWLK23423424!sldkfj9s49g499g4cccf",
  debug: true,
};
